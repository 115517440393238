import { FollowableUserEntityType, UserEntitySchoolType, UserEntityTeamType } from 'stores/userEntitiesStore';

export const findUserEntity = (userEntities: FollowableUserEntityType[], entityType: string, entityId: string, roleIds: number[]) => {
  return userEntities.find(
    userEntity =>
      userEntity.entity_type == entityType && userEntity.entity.id == entityId && (!roleIds || roleIds.includes(userEntity.role_id))
  );
};

export const findOwnedUserEntity = (userEntities: FollowableUserEntityType[], entityType: string, entityId: string, role = [1]) => {
  return findUserEntity(userEntities, entityType, entityId, role);
};

export const findFollowingUserEntity = (userEntities: FollowableUserEntityType[], entityType: string, entityId: string) => {
  return findUserEntity(userEntities, entityType, entityId, [3, 4]);
};

export const isOwned = (userEntity: FollowableUserEntityType, role_id = 1) => {
  return userEntity.role_id == role_id && (userEntity.status_id == 1 || userEntity.status_id == 2);
};

export const isManaged = (userEntity: FollowableUserEntityType, role_ids = [1, 5]) => {
  return role_ids.includes(userEntity.role_id) && (userEntity.status_id == 1 || userEntity.status_id == 2);
};

export const isPending = (userEntity: FollowableUserEntityType) => {
  return userEntity.role_id == 1 && userEntity.status_id == 3;
};

export const isFavorite = (userEntity: FollowableUserEntityType) => {
  return userEntity.role_id == 3 || userEntity.role_id == 4;
};

export const isTeam = (userEntity: FollowableUserEntityType): userEntity is UserEntityTeamType => {
  return userEntity.entity.__typename == 'Team';
};

export const isSchool = (userEntity: FollowableUserEntityType): userEntity is UserEntitySchoolType => {
  return userEntity.entity.__typename == 'School';
};

export const owned = <T extends FollowableUserEntityType>(userEntities: T[]) => {
  return userEntities.filter(entity => isOwned(entity));
};

export const managed = <T extends FollowableUserEntityType>(userEntities: T[]) => {
  return userEntities.filter(userEntity => isManaged(userEntity));
};

export const pending = <T extends FollowableUserEntityType>(userEntities: T[]) => {
  return userEntities.filter(isPending);
};

export const favorites = <T extends FollowableUserEntityType>(userEntities: T[]) => {
  return userEntities.filter(isFavorite);
};

export const teams = (userEntities: FollowableUserEntityType[]) => {
  return userEntities.filter(isTeam);
};

export const schools = (userEntities: FollowableUserEntityType[]) => {
  return userEntities.filter(isSchool);
};

export const ownedTeams = (userEntities: FollowableUserEntityType[]) => {
  return owned(teams(userEntities));
};

export const managedTeams = (userEntities: FollowableUserEntityType[]) => {
  return managed(teams(userEntities));
};

export const ownedSchools = (userEntities: FollowableUserEntityType[]) => {
  return owned(schools(userEntities));
};

export const managedSchools = (userEntities: FollowableUserEntityType[]) => {
  return managed(schools(userEntities));
};

export const pendingTeams = (userEntities: FollowableUserEntityType[]) => {
  return pending(teams(userEntities));
};

export const favoriteTeams = (userEntities: FollowableUserEntityType[]) => {
  return favorites(teams(userEntities));
};
