import styles from './styles/HeaderStatesMenu.module.scss';
import Link from 'components/Link';
import { STATES_IN_NAV } from 'helpers/nav';
import { StateType } from 'constants/States';
import { useHeaderContainer } from '..';
import StateFlag from '@/components/StateFlag';

function StateLink({ state }: { state?: StateType }) {
  const [, { makeStateHref }] = useHeaderContainer();

  return (
    <Link className={styles.Link} href={makeStateHref({ state })}>
      <StateFlag state={state} className={styles.Flag} />
      {state?.name || 'National'}
    </Link>
  );
}

export default function HeaderStatesMenu() {
  return (
    <div className={styles.Container}>
      <StateLink />
      {STATES_IN_NAV.map(state => (
        <StateLink key={state.abbrev} state={state} />
      ))}
    </div>
  );
}
