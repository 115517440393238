import Button, { ButtonVariant, ButtonProps, ButtonSize } from './Button';
import cn from 'classnames';
import styles from './styles/NavButton.module.scss';
import ExternalLink from 'icons/ExternalLink';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import Chevron from 'icons/Chevron';

type NavButtonProps = ButtonProps & {
  active?: boolean;
};

export default function NavButton({ className, variant, active, children, ...props }: NavButtonProps) {
  const [{ pathname }, { rewriteUrl }] = useApplicationContainer();

  function isActive() {
    if (active !== undefined) return active;

    if (!('href' in props)) return false;

    return props.href && rewriteUrl(pathname) === rewriteUrl(props.href);
  }

  function isExternal() {
    if (!('href' in props)) return false;

    if (props.href?.includes('www.si.com')) return false;

    return !!props.href?.match(/http/);
  }

  return (
    <Button
      variant={isActive() ? ButtonVariant.DARK_BLUE : variant || ButtonVariant.WHITE}
      size={ButtonSize.SMALL}
      className={cn(styles.NavButton, className)}
      target={isExternal() ? '_blank' : undefined}
      {...props}
    >
      {children}
      {isExternal() && <ExternalLink />}
    </Button>
  );
}

export function NavBackButton({ children, ...props }: NavButtonProps) {
  return (
    <NavButton {...props}>
      <Chevron direction="left" height={9} width={9} color="#000" /> {children || 'Back'}
    </NavButton>
  );
}
