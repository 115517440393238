import { CSSProperties } from 'react';
import { IconType } from '.';

interface SBLiveOnSIProps extends IconType {
  style?: CSSProperties;
}

export default function SBLiveOnSI({ color = '#FFFFFF', className = '', height = 25, style, width = 154 }: SBLiveOnSIProps) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 154 25">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5552" data-name="Rectangle 5552" width="154" height="25" transform="translate(0 -0.042)" fill={color} />
        </clipPath>
      </defs>
      <g id="Group_62" data-name="Group 62" transform="translate(-15 -37)">
        <g id="High_School_Logo_1_" data-name="High School Logo (1)" transform="translate(15 37.042)" clipPath="url(#clip-path)">
          <path
            id="Path_1339"
            data-name="Path 1339"
            d="M0,4.939H5.019v5.3H9.607v-5.3h5.019V19.947H9.607V14.568H5.019v5.379H0Z"
            transform="translate(0 -1.49)"
            fill={color}
          />
          <path
            id="Path_1340"
            data-name="Path 1340"
            d="M23.657,4h5.036V7.11H23.657Zm.083,3.946H28.6v11.7H23.74Z"
            transform="translate(-7.498 -1.192)"
            fill={color}
          />
          <path
            id="Path_1341"
            data-name="Path 1341"
            d="M32.686,23.367l1.5-2.938a8.731,8.731,0,0,0,4.222,1.118c1.821,0,2.789-.946,2.789-2.573V18.63a4.447,4.447,0,0,1-3.387,1.61A5.055,5.055,0,0,1,32.6,14.861v-.044c0-3.282,2.23-5.445,4.931-5.445a4.59,4.59,0,0,1,3.58,1.522V9.648h4.864V18.4c0,2.164-.448,3.647-1.522,4.715-1.051,1.051-2.656,1.716-5.274,1.716a13.868,13.868,0,0,1-6.5-1.455Zm8.533-8.533v-.044a1.826,1.826,0,0,0-1.953-1.821,1.847,1.847,0,0,0-1.953,1.865v.044a1.809,1.809,0,0,0,1.953,1.8A1.83,1.83,0,0,0,41.219,14.833Z"
            transform="translate(-10.334 -2.894)"
            fill={color}
          />
          <path
            id="Path_1342"
            data-name="Path 1342"
            d="M54.315,4h4.864V9.512a4.489,4.489,0,0,1,3.58-1.843c2.468,0,4.007,1.627,4.007,4.267v7.72H61.9V13.5a1.4,1.4,0,0,0-1.328-1.588A1.447,1.447,0,0,0,59.179,13.5v6.154H54.315Z"
            transform="translate(-17.216 -1.192)"
            fill={color}
          />
          <path
            id="Path_1343"
            data-name="Path 1343"
            d="M80.891,17.567l2.678-3.193a8.581,8.581,0,0,0,5.252,1.76c.9,0,1.284-.238,1.284-.642v-.044c0-.432-.47-.664-2.081-.985-3.365-.686-6.325-1.649-6.325-4.826V9.593c0-2.85,2.23-5.058,6.37-5.058a10.384,10.384,0,0,1,6.773,2.059L92.4,9.98a7.875,7.875,0,0,0-4.5-1.478c-.753,0-1.1.26-1.1.62v.044c0,.41.41.664,1.992.963,3.835.708,6.408,1.821,6.408,4.842v.044c0,3.154-2.6,5.08-6.624,5.08a11.605,11.605,0,0,1-7.7-2.529Z"
            transform="translate(-25.634 -1.361)"
            fill={color}
          />
          <path
            id="Path_1344"
            data-name="Path 1344"
            d="M102.838,15.572v-.044A6.15,6.15,0,0,1,109.224,9.4a5.593,5.593,0,0,1,5.3,3.237l-3.492,1.97a1.881,1.881,0,0,0-1.716-1.179,1.906,1.906,0,0,0-1.8,2.059v.044a1.9,1.9,0,0,0,1.738,2.1,2.027,2.027,0,0,0,1.82-1.179l3.537,2.014a5.9,5.9,0,0,1-5.617,3.193A6.074,6.074,0,0,1,102.838,15.572Z"
            transform="translate(-32.596 -2.902)"
            fill={color}
          />
          <path
            id="Path_1345"
            data-name="Path 1345"
            d="M121.164,4h4.865V9.512a4.487,4.487,0,0,1,3.58-1.843c2.468,0,4.006,1.627,4.006,4.267v7.72h-4.864V13.5a1.4,1.4,0,0,0-1.329-1.588,1.446,1.446,0,0,0-1.394,1.588v6.154h-4.865Z"
            transform="translate(-38.405 -1.192)"
            fill={color}
          />
          <path
            id="Path_1346"
            data-name="Path 1346"
            d="M140.867,15.539v-.044a6.3,6.3,0,0,1,6.6-6.132,6.235,6.235,0,0,1,6.563,6.087v.044a6.3,6.3,0,0,1-6.6,6.131A6.235,6.235,0,0,1,140.867,15.539Zm8.489,0v-.044a2,2,0,0,0-1.931-2.081,1.953,1.953,0,0,0-1.887,2.036v.044a2,2,0,0,0,1.932,2.081A1.953,1.953,0,0,0,149.356,15.539Z"
            transform="translate(-44.65 -2.892)"
            fill={color}
          />
          <path
            id="Path_1347"
            data-name="Path 1347"
            d="M161.2,15.539v-.044a6.3,6.3,0,0,1,6.6-6.132,6.234,6.234,0,0,1,6.563,6.087v.044a6.3,6.3,0,0,1-6.6,6.131A6.235,6.235,0,0,1,161.2,15.539Zm8.489,0v-.044a2,2,0,0,0-1.931-2.081,1.953,1.953,0,0,0-1.887,2.036v.044a2,2,0,0,0,1.932,2.081A1.953,1.953,0,0,0,169.692,15.539Z"
            transform="translate(-51.096 -2.892)"
            fill={color}
          />
          <path id="Path_1348" data-name="Path 1348" d="M182.139,4H187V19.65h-4.864Z" transform="translate(-57.731 -1.192)" fill={color} />
          <path
            id="Path_1349"
            data-name="Path 1349"
            d="M197.984,2.983a10.185,10.185,0,1,1,14.4,14.4l-7.2,7.2-7.2-7.2a10.366,10.366,0,0,1-2.863-5.633A10.035,10.035,0,0,1,197.984,2.983Zm1.8,2.179a6.4,6.4,0,0,0-.064,1.085v.87a6.122,6.122,0,0,0,.066,1.1,1.015,1.015,0,0,0,.413.58,2.378,2.378,0,0,0,.916.4,6.24,6.24,0,0,0,2.6-.007,2.341,2.341,0,0,0,.9-.4,1,1,0,0,0,.4-.583,6.323,6.323,0,0,0,.064-1.085v-.87a6.057,6.057,0,0,0-.066-1.1,1.02,1.02,0,0,0-.413-.58,2.374,2.374,0,0,0-.915-.4,5.619,5.619,0,0,0-1.283-.134,5.466,5.466,0,0,0-1.32.141,2.337,2.337,0,0,0-.9.4A1.009,1.009,0,0,0,199.787,5.162Zm3.6,11.821c2.459,0,3.607-.449,3.607-2.05v-.373c0-1.132-.711-1.6-2.94-2.05-.665-.137-.918-.273-.918-.683v-.254a.317.317,0,0,1,.345-.294.3.3,0,0,1,.345.294v.8h3.055v-.488c0-1.327-.713-2.03-3.561-2.03-2.068,0-3.607.625-3.607,2.011v.254c0,1.21.621,1.757,2.94,2.245l.154.033c.616.129.833.175.833.573v.254c0,.254-.069.352-.345.352-.322,0-.345-.176-.345-.371v-.664h-3.24v.312C199.709,16.495,200.789,16.983,203.385,16.983Zm4.2-.21h3.074V10.065h-3.074Zm1.046-7.551h2.029V4.14h-1.935V6.428l-1.3-2.288h-1.936V9.222h1.936V6.912ZM202.7,8.3c-.044.08-.146.119-.3.119h0c-.152,0-.251-.035-.293-.1a1.362,1.362,0,0,1-.064-.546V5.4c0-.261.032-.419.1-.472a.426.426,0,0,1,.277-.08.326.326,0,0,1,.283.1.93.93,0,0,1,.069.453V7.732A1.462,1.462,0,0,1,202.7,8.3Z"
            transform="translate(-61.808 0.076)"
            fill={color}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
}
