import { useState } from 'react';
import styles from './styles/Header.module.scss';
import FlexContainer from 'components/FlexContainer';
import { STATES_IN_NAV } from 'helpers/nav';
import Selector from 'components/Selector';
import Search from 'components/Search';
import queries from 'queries';
import { selectTypename } from '@sblive-sports/api-client-js';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export function displayParser(result: { name: string; image?: string; location_text: string; web_path: string }) {
  return {
    name: result.name,
    image: result.image,
    bottomText: result.location_text,
    link: result.web_path
  };
}

const HeaderSearchMenu = () => {
  const [{ state }] = useApplicationContainer();
  const [selectedState, setSelectedState] = useState(state);
  const stateOptions = STATES_IN_NAV;

  function handleSearch(query: string) {
    return queries.searchClientQuery({ query, state: selectedState?.abbrev, first: 20 }).then(({ search: { nodes } }) => {
      return nodes.filter(selectTypename('School'));
    });
  }

  return (
    <div className={styles.NavSubMenu}>
      {/*<div className={styles.NavSubMenuHeader}>School Search</div>*/}
      <FlexContainer>
        <div style={{ marginBottom: '15px', overflow: 'hidden' }}>
          <Selector
            label="state"
            placeholder="All States"
            value={selectedState}
            onChange={setSelectedState}
            options={stateOptions}
            labelExtractor={o => o.name}
            valueExtractor={o => o.abbrev}
          />
        </div>
        <div style={{ paddingRight: '5px' }}>
          {/*{ currentState && SECTIONS[currentState.abbrev] && */}
          {/*  <SBLSelector*/}
          {/*    label='section'*/}
          {/*    name='section'*/}
          {/*    onChange={(e) => setSelectedState(e.target.value)}*/}
          {/*    placeholder='All States'*/}
          {/*    className={styles.modalSelect}*/}
          {/*    value={selectedState}*/}
          {/*    options={stateOptions}/>*/}
          {/*}*/}
        </div>
        <div />
      </FlexContainer>
      <Search onSearch={handleSearch} displayParser={displayParser} />
    </div>
  );
};

export default HeaderSearchMenu;
