import { CSSProperties } from 'react';
import { IconType } from '.';

interface StarProps extends IconType {
  style?: CSSProperties;
  isFilled?: boolean;
}

export default function Star({ color = '#FFF', className, height = 14, isFilled, style, width = 14 }: StarProps) {
  return isFilled ? (
    <svg width={width + 2} height={height + 2} style={style} className={className} viewBox="0 0 576 512">
      <path
        fill={color}
        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
      />
    </svg>
  ) : (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 14.02 14.02">
      <path
        fill={color}
        d="M12.031,15.675a.52.52,0,0,1-.251-.065L8.034,13.543,4.287,15.61a.519.519,0,0,1-.569-.043.58.58,0,0,1-.215-.554l.716-4.376-3.032-3.1a.586.586,0,0,1-.137-.581.548.548,0,0,1,.436-.386l4.189-.637L7.547,1.946a.549.549,0,0,1,.969,0l1.875,3.986,4.189.638a.548.548,0,0,1,.436.386.586.586,0,0,1-.137.581l-3.031,3.1.715,4.378a.587.587,0,0,1-.12.46A.53.53,0,0,1,12.031,15.675Zm-4-3.339a.52.52,0,0,1,.251.065l3.03,1.672-.58-3.54a.584.584,0,0,1,.156-.5l2.451-2.507L9.954,7.008A.543.543,0,0,1,9.547,6.7L8.034,3.477,6.521,6.7a.543.543,0,0,1-.407.311l-3.387.516,2.449,2.507a.584.584,0,0,1,.157.5l-.58,3.54L7.782,12.4A.52.52,0,0,1,8.034,12.337Z"
        transform="translate(-1.024 -1.655)"
      />
    </svg>
  );
}
