import Link from 'components/Link';
import { HTMLAttributes } from 'react';

interface LinkIfProps extends HTMLAttributes<HTMLElement> {
  href?: string;
  target?: string;
}

export default function LinkIf({ href, target, ...props }: LinkIfProps) {
  if (href) {
    return <Link href={href} target={target} {...props} />;
  } else {
    return <div {...props} />;
  }
}
