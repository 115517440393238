import { MouseEvent, ReactNode } from 'react';
import styles from './styles/Modal.module.scss';
import ReactDOM from 'react-dom';
import Button, { ButtonType } from './Button';
import cn from 'classnames';
import Close from '../icons/Close';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  submitText?: string;
  onSubmit?: () => void;
  buttonType?: ButtonType;
  submitText2?: string;
  onSubmit2?: () => void;
  buttonType2?: ButtonType;
  submitText3?: string;
  onSubmit3?: () => void;
  buttonType3?: ButtonType;
  children: ReactNode;
  stickyBottomButton?: boolean;
  title: ReactNode;
  className?: string;
  modalClassName?: string;
  modalCloseClassName?: string;
  scrollable?: boolean;
  autoId?: string;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  submitText,
  submitText2,
  onSubmit,
  className,
  modalClassName,
  modalCloseClassName,
  onSubmit2,
  buttonType,
  buttonType2,
  stickyBottomButton,
  title,
  scrollable,
  autoId,
  onSubmit3,
  submitText3,
  buttonType3
}: ModalProps) => {
  if (!isOpen) return null;

  const handleModalClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return ReactDOM.createPortal(
    <div className={cn(styles.ModalContainer, className)} onClick={onClose}>
      <div className={cn(styles.Modal, modalClassName)} onClick={handleModalClick}>
        <div className={styles.ModalHeader}>
          <div className={styles.ModalTitle}>{title}</div>
          <div className={cn(styles.ModalClose, modalCloseClassName)} onClick={onClose}>
            <Close height={15} width={15} color="#000" strokeWidth={3} />
          </div>
        </div>
        <div className={cn(styles.ModalContent, { [styles.ModalContentScrollable]: scrollable })}>{children}</div>
        <div className={styles.ModalBottomButton}>
          {submitText && <Button autoId={autoId} label={submitText} type={buttonType} onClick={() => (onSubmit ? onSubmit() : null)} />}
          {submitText2 && <Button label={submitText2} type={buttonType2} onClick={() => (onSubmit2 ? onSubmit2() : null)} />}
          {submitText3 && <Button label={submitText3} type={buttonType3} onClick={() => (onSubmit3 ? onSubmit3() : null)} />}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
