import { useHeaderContainer } from '..';
import NavBar from '@/components/NavBar';
import NavButton from '@/components/NavButton';

export default function HeaderSubNav() {
  const [{ subNavLinks }] = useHeaderContainer();

  return (
    <NavBar>
      {subNavLinks.map(({ text, href }) => {
        return (
          <NavButton key={text} href={href}>
            {text}
          </NavButton>
        );
      })}
    </NavBar>
  );
}
