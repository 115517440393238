import { ImgHTMLAttributes } from 'react';
import { DEFAULT_TEAM_IMAGE } from 'constants.js.erb';
import Image from 'components/Image';

interface TeamImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'alt'> {
  alt: string; // makes alt required
  showImageOnTba?: boolean;
}

export default function TeamImage({ alt, src, showImageOnTba, ...props }: TeamImageProps) {
  if (!showImageOnTba && alt?.match(/TBA/)) return null;

  return (
    <Image
      src={src || DEFAULT_TEAM_IMAGE}
      alt={alt}
      onError={src ? ({ target }) => ((target as HTMLImageElement).src = DEFAULT_TEAM_IMAGE) : undefined}
      {...props}
    />
  );
}
