import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Croppie from 'croppie/croppie';
import EXIF from 'exif-js';

class ImageWithCrop extends React.Component {
  state = {
    activeCrop: false,
    file: null
  };

  croppieImg = React.createRef();
  croppieInput = React.createRef();

  componentDidMount() {
    window.EXIF = EXIF;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeCrop && this.state.file && prevState.file !== this.state.file) {
      this.readFile();
    }
  }

  readFile = () => {
    const file = this.croppieInput.current.files[0];

    if (!this.croppie) {
      this.croppie = new Croppie(this.croppieImg.current, {
        enableExif: true,
        viewport: {
          type: this.props.previewType,
          width: this.props.previewWidth,
          height: this.props.previewHeight
        },
        boundary: {
          width: this.props.previewWidth + this.props.previewMargin,
          height: this.props.previewHeight + this.props.previewMargin
        }
      });
    }
    const reader = new FileReader();
    reader.onload = e => {
      this.croppie.bind({
        url: e.target.result,
        orientation: 1
      });
    };
    reader.readAsDataURL(file);
  };

  handleChange = e => {
    this.setState({
      activeCrop: true,
      file: e.target.files[0]
    });
  };

  removeCroppie = e => {
    e.preventDefault();

    if (this.croppie) {
      this.croppie.destroy();
      this.croppie = null;
    }

    this.setState({
      activeCrop: false
    });
  };

  save = e => {
    e.preventDefault();
    e.persist();

    const croppedImage = this.croppie.result({
      type: 'base64',
      size: { width: this.props.imageWidth, height: this.props.imageHeight },
      quality: this.props.imageQuality,
      format: 'png',
      circle: this.props.imageCircle
    });

    Promise.resolve(croppedImage).then(image => {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: image
        }
      });

      this.removeCroppie(e);
    });
  };

  handleClick = e => {
    e.preventDefault();
    this.croppieInput.current.click();
  };

  render() {
    const { name, value, className, placeholder, disabled, imagePlaceholder } = this.props;

    return [
      <div key="image" className={classNames('image-input', className)}>
        {value ? <img src={value} alt={placeholder} /> : imagePlaceholder ? <img src={imagePlaceholder} alt={placeholder} /> : ''}
        <input
          type="file"
          name={name}
          onChange={this.handleChange}
          disabled={disabled}
          ref={this.croppieInput}
          accept=".png, .jpg, .jpeg, .gif"
        />
        {!this.props.disabled && <button type="button" className="upload-btn" onClick={this.handleClick} />}
      </div>,
      this.state.activeCrop ? (
        <div key="popover" className="cropper-popover">
          <div className="title">Position and size your image</div>
          <div ref={this.croppieImg} />
          <button type="button" className="btn" onClick={e => this.save(e)}>
            Set new {placeholder} image
          </button>
        </div>
      ) : null
    ];
  }
}

ImageWithCrop.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  imagePlaceholder: PropTypes.string,
  onChange: PropTypes.func,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  imageQuality: PropTypes.number,
  imageCircle: PropTypes.bool,
  previewHeight: PropTypes.number,
  previewWidth: PropTypes.number,
  previewMargin: PropTypes.number,
  previewType: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string
};

ImageWithCrop.defaultProps = {
  imageWidth: 600,
  imageHeight: 600,
  imageQuality: 0.75,
  previewHeight: 100,
  previewWidth: 100,
  previewMargin: 0,
  imageCircle: false,
  previewType: 'circle'
};

export default ImageWithCrop;
