import { ReactNode } from 'react';
import styles from './styles/NavBar.module.scss';

/* SHOULD BE PAGESECTION EVENTUALLY

<PageSection className={styles.Content} flush={ScreenSize.MEDIUM} fullWidth>
  {left && <div className={styles.Left}>{left}</div>}
  <div className={styles.Links}>{children}</div>
</PageSection>
*/

export default function NavBar({ children, left }: { children?: ReactNode; left?: ReactNode }) {
  return (
    <div className={styles.NavBar}>
      <div className={styles.Content}>
        {left && <div className={styles.Left}>{left}</div>}
        <div className={styles.Links}>{children}</div>
      </div>
    </div>
  );
}
