import { useState } from 'react';
import { logoutUser, useUser } from 'stores/userStore';
import { useUserEntities } from 'stores/userEntitiesStore';
import { favorites as favoriteTeams, managedSchools, managedTeams } from 'actions/user_entities_actions';
import styles from './styles/Header.module.scss';
import HeaderFavoritesMenu from './HeaderFavoritesMenu';
import AccountModal from 'components/AccountModal';
import cn from 'classnames';
import { WEB_NEXT_BASE_URL } from 'constants.js.erb';
import Clickable from '@/components/Clickable';
import { BackParam } from 'components/Link';

interface HeaderAccountMenuProps {
  handleOpenPassword: () => void;
  handleOpenAccountDelete: () => void;
}

const HeaderAccountMenu = ({ handleOpenPassword, handleOpenAccountDelete }: HeaderAccountMenuProps) => {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showFollowingMenu, setShowFollowingMenu] = useState(false);
  const userData = useUser();
  const userEntitiesData = useUserEntities();

  const managedSchoolEntities = managedSchools(userEntitiesData.userEntities);
  const managedTeamEntities = managedTeams(userEntitiesData.userEntities);
  const favorites = favoriteTeams(userEntitiesData.userEntities);

  const showFavoriteTeams = favoriteTeams && favoriteTeams.length > 0;
  const showSchoolManager = managedSchoolEntities && managedSchoolEntities.length > 0;
  const showTeamManager = managedTeamEntities && managedTeamEntities.length > 0;
  const showTournamentManager = userData.account && userData.account.roles.includes('tournament_manager');
  const showStateManagerLink = userData.isAdmin;

  const schoolManagerLink = showSchoolManager
    ? managedSchoolEntities.length === 1
      ? `/admin/schools/${managedSchoolEntities[0].entity.id}`
      : `/admin/schools`
    : '';
  const teamManagerLink = showTeamManager
    ? managedTeamEntities.length === 1
      ? `/admin/teams/${managedTeamEntities[0].entity.id}/games`
      : `/admin/teams`
    : '';

  const openChangePasswordModal = () => {
    setShowAccountModal(false);
    handleOpenPassword();
  };

  const openAccountDeleteModal = () => {
    setShowAccountModal(false);
    handleOpenAccountDelete();
  };

  const handleFollowingClick = () => setShowFollowingMenu(!showFollowingMenu);
  const supportLink = 'https://sites.google.com/scorebooklive.com/sblive-academy/home?authuser=0';

  return (
    <div className={styles.FavoritesMenu}>
      {showAccountModal && (
        <AccountModal
          onClose={() => setShowAccountModal(false)}
          openPasswordModal={openChangePasswordModal}
          isOpen={showAccountModal}
          openAccountDeleteModal={openAccountDeleteModal}
        />
      )}
      <Clickable className={styles.FavoritesRow} onClick={() => setShowAccountModal(true)}>
        My Account
      </Clickable>
      {showSchoolManager && (
        <Clickable className={styles.FavoritesRow} href={schoolManagerLink} back={BackParam.ADD}>
          School Manager
        </Clickable>
      )}
      {showTeamManager && (
        <Clickable className={styles.FavoritesRow} href={teamManagerLink} back={BackParam.ADD}>
          Team Manager
        </Clickable>
      )}
      {showTournamentManager && (
        <Clickable className={styles.FavoritesRow} href="/admin/brackets" back={BackParam.ADD}>
          Tournament Manager
        </Clickable>
      )}
      {showStateManagerLink && (
        <Clickable className={styles.FavoritesRow} href={`${WEB_NEXT_BASE_URL}/sblive/admin/contests`} target="_blank">
          SBLive Manager
        </Clickable>
      )}
      <div className={styles.ViewMoreBorder} />
      <Clickable className={styles.FavoritesRow} href={supportLink} target="_blank">
        Support
      </Clickable>
      {/*<Clickable className={styles.FavoritesRow} href={supportLink} target='_blank'>SBLive Academy</Clickable>*/}
      <Clickable className={styles.FavoritesRow} href="/about" target="_blank">
        About Us
      </Clickable>
      <Clickable className={styles.FavoritesRow} href="/terms" target="_blank">
        Terms & Conditions
      </Clickable>
      <Clickable className={styles.FavoritesRow} href="/privacy" target="_blank">
        Privacy Policy
      </Clickable>
      {/*{ showFavoriteTeams && <Clickable className={styles.FavoritesRow} onClick={handleFollowingClick}>Following</Clickable> }*/}
      {/*{ showFollowingMenu && <HeaderFavoritesMenu favorites={favorites}/> }*/}
      <div className={styles.ViewMoreBorder} />
      <Clickable onClick={logoutUser} className={styles.FavoritesRow}>
        Sign Out
      </Clickable>
    </div>
  );
};

export default HeaderAccountMenu;
