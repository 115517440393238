import { GenderSportType, genderSportsByState } from 'constants/GenderSports';
import styles from './styles/HeaderGenderSportsMenu.module.scss';
import Link from 'components/Link';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { useHeaderContainer } from '..';
import { CSSProperties } from 'react';

const alphabeticalSort = (a: string, b: string) => {
  let strA = a.toUpperCase(); // ignore upper and lowercase
  let strB = b.toUpperCase(); // ignore upper and lowercase

  strA = strA.replace('BOYS-', '');
  strA = strA.replace('GIRLS-', '');
  strB = strB.replace('BOYS-', '');
  strB = strB.replace('GIRLS-', '');

  if (strA < strB) return -1;
  if (strA > strB) return 1;
  return 0;
};

function GenderSportLink({ genderSport }: { genderSport?: GenderSportType }) {
  const [, { makeGenderSportHref }] = useHeaderContainer();

  return (
    <Link href={makeGenderSportHref({ genderSport })} className={styles.Link}>
      {genderSport?.name || 'All Sports'}
    </Link>
  );
}

interface ContainerCss extends CSSProperties {
  '--rows': number;
}

export default function HeaderGenderSportsMenu() {
  const [{ state }] = useApplicationContainer();
  const [, { makeGenderSportHref }] = useHeaderContainer();

  const genderSports = genderSportsByState(state?.abbrev).sort((a, b) => alphabeticalSort(a.slug, b.slug));
  const rows = Math.ceil((genderSports.length + 1) / 3);

  return (
    <div
      className={styles.Container}
      style={
        {
          '--rows': rows
        } as ContainerCss
      }
    >
      <GenderSportLink />
      {genderSports.map(genderSport => (
        <GenderSportLink key={genderSport.slug} genderSport={genderSport} />
      ))}
    </div>
  );
}
