import { GenderSportType } from 'constants/GenderSports';
import { StateType } from 'constants/States';
import { createContainer } from 'containers';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import compact from 'helpers/compact';
import {
  NEWS,
  SCORES,
  SUBNAV_OPTIONS,
  findSubNavOption,
  getSubNavPathIds,
  hasPathId,
  getSubNavPathIdsForKey,
  sortPathIds
} from 'helpers/nav';

export interface HeaderContainerProps {}

export const [HeaderContainer, useHeaderContainer] = createContainer(({}: HeaderContainerProps) => {
  const [{ state, genderSport, pathname, subnavTags }, { makePath }] = useApplicationContainer();

  const parts = pathname.replace('/games/', '/scores/').split('/').filter(compact);
  const pathId = SUBNAV_OPTIONS.reverse().find(option => parts.includes(option.pathname))?.id || NEWS;

  function makeHref({
    genderSport = null,
    state = null,
    pathId
  }: {
    genderSport?: GenderSportType | null;
    state?: StateType | null;
    pathId: number;
  }) {
    return makePath({ genderSport, state, path: findSubNavOption(pathId)?.pathname });
  }

  function makeSubNavHref({ pathId }: { pathId: number }) {
    return makeHref({ genderSport, state, pathId });
  }

  function makeNavHref({ genderSport, state }: { genderSport?: GenderSportType; state?: StateType }) {
    if (hasPathId({ state, genderSport, pathId })) {
      return makeHref({ genderSport, state, pathId });
    }

    return makeHref({ genderSport, state, pathId: getSubNavPathIds({ genderSport, state })?.at(0) || SCORES });
  }

  function makeGenderSportHref({ genderSport }: { genderSport?: GenderSportType }) {
    return makeNavHref({ genderSport, state });
  }

  function makeStateHref({ state }: { state?: StateType }) {
    return makeNavHref({ genderSport, state });
  }

  function getAllSubNavPathIds({
    genderSport,
    state,
    subnavTags
  }: {
    genderSport?: GenderSportType;
    state?: StateType;
    subnavTags: string[];
  }) {
    // TODO: Combining the lists until we pull everything from the SubnavConstants
    const subnavPathIds = getSubNavPathIds({ genderSport, state });
    const subnavConstantsIds = subnavTags.map(subnavTag => getSubNavPathIdsForKey(subnavTag)).filter(compact);

    return Array.from(new Set(subnavPathIds.concat(subnavConstantsIds))).sort(sortPathIds);
  }

  const subNavLinks = getAllSubNavPathIds({ genderSport, state, subnavTags })
    .map(id => findSubNavOption(id))
    .filter(compact)
    .map(({ id, name }) => ({
      text: name,
      href: makeSubNavHref({ pathId: id })
    }));

  return [{ subNavLinks }, { makeGenderSportHref, makeStateHref }];
});
