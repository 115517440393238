import styles from './styles/TeamBlock.module.scss';
import { ReactNode } from 'react';
import TeamImage from './TeamImage';
import LinkIf from 'components/LinkIf';
import cn from 'classnames';
import TextTruncateComponent from './TextTruncateComponent';

interface TeamBlockProps {
  image: string | undefined;
  name: string;
  textBlock?: ReactNode;
  abbrev?: string;
  link?: string;
  className?: string;
  v2?: boolean;
}

function TeamBlock({ image, name, abbrev, link, className, textBlock, v2 }: TeamBlockProps) {
  return (
    <div className={cn(styles.TeamBlock, className, { [styles.SmallTeamImage]: !!abbrev, [styles.v2]: v2 })}>
      <LinkIf className={styles.TeamBlockLink} href={['TBA', 'TBD'].includes(name) ? undefined : link}>
        <div className={cn(styles.TeamBlockImage, 'TeamBlockImageWrapper')}>
          <TeamImage src={image} alt={name} />
          {abbrev && <div>{abbrev}</div>}
        </div>
        <div className={styles.TeamBlockText}>
          {v2 ? textBlock ?? <TextTruncateComponent line={1} text={name} /> : <div>{textBlock || name}</div>}
        </div>
      </LinkIf>
    </div>
  );
}

export default TeamBlock;
