import TextTruncate, { TextTruncateProps } from 'react-text-truncate';
import styles from './styles/TextTruncateComponent.module.scss';
import cn from 'classnames';

function TextTruncateComponent(props: TextTruncateProps) {
  return (
    // not setting line height if there are multiple lines, you should set in container class name
    <TextTruncate
      {...props}
      containerClassName={cn({ [styles.TextTruncateComponent]: props.line == 1 || !props.line }, props.containerClassName)}
      maxCalculateTimes={100000}
    />
  );
}

export default TextTruncateComponent;
