import { CSSProperties } from 'react';
import { IconType } from '.';

interface SearchProps extends IconType {
  style?: CSSProperties;
}

export default function Search({ color = '#FFF', className = 'icon', height = 16, style, width = 16 }: SearchProps) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 16.613 16.613">
      <path
        fill={color}
        data-name="Path 1145"
        d="M17.581,16.5l-3.033-3.033a7.654,7.654,0,1,0-1.081,1.081L16.5,17.581ZM8.635,14.748a6.114,6.114,0,1,1,6.114-6.114A6.114,6.114,0,0,1,8.635,14.748Z"
        transform="translate(-0.968 -0.968)"
      />
    </svg>
  );
}
