import { HeaderContainer, HeaderContainerProps } from 'containers/HeaderContainer';
import HeaderNav from 'containers/HeaderContainer/components/HeaderNav';
import HeaderSubNav from 'containers/HeaderContainer/components/HeaderSubNav';
import styles from './styles/Header.module.scss';
import { ReactNode } from 'react';

export type HeaderProps = Omit<HeaderContainerProps, 'children'> & { subNav?: ReactNode };

export default function Header({ subNav, ...props }: HeaderProps) {
  return (
    <header className={styles.FixedHeader}>
      <HeaderContainer {...props}>
        <HeaderNav />
        {subNav || <HeaderSubNav />}
      </HeaderContainer>
    </header>
  );
}
