import { useState } from 'react';
import styles from './styles/Header.module.scss';
import Link from 'components/Link';
import TeamImage from 'components/TeamImage';
import useScreenSize from 'hooks/useScreenSize';
import { UserEntityTeamType } from 'stores/userEntitiesStore';
import Clickable from '@/components/Clickable';

interface HeaderFavoritesMenuProps {
  favorites: UserEntityTeamType[];
}

const HeaderFavoritesMenu = ({ favorites }: HeaderFavoritesMenuProps) => {
  const { isSmallScreen } = useScreenSize();
  const teamCopy = [...favorites];
  const [currentTeamNumber, setCurrentTeamNumber] = useState(6);
  const hasMoreTeams = !isSmallScreen && teamCopy.length > currentTeamNumber;

  return (
    <div className={styles.FavoritesMenu}>
      {teamCopy.splice(0, isSmallScreen ? teamCopy.length : currentTeamNumber).map(team => {
        const entity = team.entity;
        return (
          <Link key={team.id} className={styles.FavoritesRow} href={team.entity.web_path}>
            <TeamImage alt={entity.name} src={entity.image} />
            <div>
              <div>
                {entity.name} {entity.mascot}
              </div>
              <div>{entity.division_text}</div>
            </div>
          </Link>
        );
      })}
      {hasMoreTeams && (
        <>
          <div className={styles.ViewMoreBorder} />
          <Clickable className={styles.ViewMoreTeams} onClick={() => setCurrentTeamNumber(currentTeamNumber + 6)}>
            View More Teams
          </Clickable>
        </>
      )}
    </div>
  );
};

export default HeaderFavoritesMenu;
