import { StateEnum } from 'queries';
import AK from 'images/states/AK.png';
import AL from 'images/states/AL.png';
import AR from 'images/states/AR.png';
import AZ from 'images/states/AZ.png';
import CA from 'images/states/CA.png';
import CO from 'images/states/CO.png';
import CT from 'images/states/CT.png';
import DC from 'images/states/DC.png';
import DE from 'images/states/DE.png';
import FL from 'images/states/FL.png';
import GA from 'images/states/GA.png';
import HI from 'images/states/HI.png';
import IA from 'images/states/IA.png';
import ID from 'images/states/ID.png';
import IL from 'images/states/IL.png';
import IN from 'images/states/IN.png';
import KS from 'images/states/KS.png';
import KY from 'images/states/KY.png';
import LA from 'images/states/LA.png';
import MA from 'images/states/MA.png';
import MD from 'images/states/MD.png';
import ME from 'images/states/ME.png';
import MI from 'images/states/MI.png';
import MN from 'images/states/MN.png';
import MO from 'images/states/MO.png';
import MS from 'images/states/MS.png';
import MT from 'images/states/MT.png';
import NC from 'images/states/NC.png';
import ND from 'images/states/ND.png';
import NE from 'images/states/NE.png';
import NH from 'images/states/NH.png';
import NJ from 'images/states/NJ.png';
import NM from 'images/states/NM.png';
import NV from 'images/states/NV.png';
import NY from 'images/states/NY.png';
import OH from 'images/states/OH.png';
import OK from 'images/states/OK.png';
import OR from 'images/states/OR.png';
import PA from 'images/states/PA.png';
import RI from 'images/states/RI.png';
import SC from 'images/states/SC.png';
import SD from 'images/states/SD.png';
import TN from 'images/states/TN.png';
import TX from 'images/states/TX.png';
import USA from 'images/states/USA.png';
import UT from 'images/states/UT.png';
import VA from 'images/states/VA.png';
import VT from 'images/states/VT.png';
import WA from 'images/states/WA.png';
import WI from 'images/states/WI.png';
import WV from 'images/states/WV.png';
import WY from 'images/states/WY.png';
import Image, { ImageProps } from 'components/Image';

const FLAGS: Partial<Record<StateEnum, string>> = {
  [StateEnum.ALASKA]: AK,
  [StateEnum.ALABAMA]: AL,
  [StateEnum.ARKANSAS]: AR,
  [StateEnum.ARIZONA]: AZ,
  [StateEnum.CALIFORNIA]: CA,
  [StateEnum.COLORADO]: CO,
  [StateEnum.CONNECTICUT]: CT,
  [StateEnum.DISTRICT_OF_COLUMBIA]: DC,
  [StateEnum.DELAWARE]: DE,
  [StateEnum.FLORIDA]: FL,
  [StateEnum.GEORGIA]: GA,
  [StateEnum.HAWAII]: HI,
  [StateEnum.IOWA]: IA,
  [StateEnum.IDAHO]: ID,
  [StateEnum.ILLINOIS]: IL,
  [StateEnum.INDIANA]: IN,
  [StateEnum.KANSAS]: KS,
  [StateEnum.KENTUCKY]: KY,
  [StateEnum.LOUISIANA]: LA,
  [StateEnum.MASSACHUSETTS]: MA,
  [StateEnum.MARYLAND]: MD,
  [StateEnum.MAINE]: ME,
  [StateEnum.MICHIGAN]: MI,
  [StateEnum.MINNESOTA]: MN,
  [StateEnum.MISSOURI]: MO,
  [StateEnum.MISSISSIPPI]: MS,
  [StateEnum.MONTANA]: MT,
  [StateEnum.NORTH_CAROLINA]: NC,
  [StateEnum.NORTH_DAKOTA]: ND,
  [StateEnum.NEBRASKA]: NE,
  [StateEnum.NEW_HAMPSHIRE]: NH,
  [StateEnum.NEW_JERSEY]: NJ,
  [StateEnum.NEW_MEXICO]: NM,
  [StateEnum.NEVADA]: NV,
  [StateEnum.NEW_YORK]: NY,
  [StateEnum.OHIO]: OH,
  [StateEnum.OKLAHOMA]: OK,
  [StateEnum.OREGON]: OR,
  [StateEnum.PENNSYLVANIA]: PA,
  [StateEnum.RHODE_ISLAND]: RI,
  [StateEnum.SOUTH_CAROLINA]: SC,
  [StateEnum.SOUTH_DAKOTA]: SD,
  [StateEnum.TENNESSEE]: TN,
  [StateEnum.TEXAS]: TX,
  [StateEnum.UTAH]: UT,
  [StateEnum.VIRGINIA]: VA,
  [StateEnum.VERMONT]: VT,
  [StateEnum.WASHINGTON]: WA,
  [StateEnum.WISCONSIN]: WI,
  [StateEnum.WEST_VIRGINIA]: WV,
  [StateEnum.WYOMING]: WY,
  [StateEnum.PUERTO_RICO]: USA
};

export function getStateFlagSrc(state?: StateEnum) {
  if (!state) return USA;
  return FLAGS[state] || USA;
}

export default function StateFlag({ state, ...props }: { state?: { key: StateEnum; name: string } } & ImageProps) {
  return <Image src={getStateFlagSrc(state?.key)} alt={state?.name || 'USA'} {...props} />;
}
