import styles from 'components/styles/AccountModal.module.scss';
import { useEffect, useState } from 'react';
import Modal from './Modal';
import Input from './Input';
import FlexContainer from './FlexContainer';
import Label from './Label';
import { updateUserAccount, useUser } from 'stores/userStore';
import Selector from './Selector';
import STATES from 'constants/States';
import { DEFAULT_PROFILE_IMAGE } from 'constants.js.erb';
import LoadingModal from './LoadingModal';
// @ts-ignore
import ImageWithCrop from 'react/form/image_with_crop';
import Errors from './Errors';

interface AccountModalProps {
  onClose: () => void;
  isOpen: boolean;
  openPasswordModal: () => void;
  openAccountDeleteModal: () => void;
}

function AccountModal({ onClose, isOpen, openPasswordModal, openAccountDeleteModal }: AccountModalProps) {
  const user = useUser();
  const [username, setUsername] = useState(user.account?.username);
  const [email, setEmail] = useState(user.account?.email);
  const [state, setState] = useState(STATES.find(state => state.abbrev === user.account?.state));
  const [image, setImage] = useState(user.account?.image);
  const [isSaving, setisSaving] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  useEffect(() => {
    setUsername(user.account?.username);
    setState(STATES.find(state => state.abbrev === user.account?.state));
    setImage(user.account?.image);
    setEmail(user.account?.email);
  }, [user]);

  function onSubmit() {
    setisSaving(true);
    setErrors(null);
    updateUserAccount({
      username: username,
      email: email,
      state: state?.abbrev,
      image: image
    })
      .then(response => {
        setisSaving(false);
        onClose();
      })
      .catch(errors => {
        setisSaving(false);
        setErrors(errors);
      });
  }

  if (!user.account) return null;

  const stateOptions = STATES.filter(state => state.isAmerican);
  return (
    <Modal
      title="Account"
      onClose={onClose}
      isOpen={isOpen}
      submitText="Save"
      onSubmit={onSubmit}
      submitText2="Change Password"
      buttonType2="white"
      onSubmit2={openPasswordModal}
      submitText3="Delete Account"
      buttonType3="negative"
      onSubmit3={openAccountDeleteModal}
    >
      <LoadingModal isOpen={isSaving} />
      <form onSubmit={onSubmit} className={styles.AccountModal}>
        <Errors errors={errors} />
        <ImageWithCrop
          name="image"
          className={styles.accountImage}
          placeholder="User"
          imagePlaceholder={DEFAULT_PROFILE_IMAGE}
          value={image}
          previewMargin={10}
          onChange={(value: any) => setImage(value.target.value)}
        />
        <FlexContainer column className={styles.ModalSelect}>
          <Label text="Username" />
          <Input type="text" data-automation-id="loginUsername" placeholder="Username" value={username} changeFunc={setUsername} />
        </FlexContainer>
        <FlexContainer column className={styles.ModalSelect}>
          <Label text="Email" />
          <Input type="text" data-automation-id="loginEmail" placeholder="Email" value={email} changeFunc={setEmail} />
        </FlexContainer>
        <FlexContainer column className={styles.ModalSelect}>
          <Selector
            label="State"
            options={stateOptions}
            labelExtractor={s => s.name}
            valueExtractor={s => s.abbrev}
            value={state}
            onChange={s => setState(s)}
            placeholder="Select State"
          />
        </FlexContainer>
      </form>
    </Modal>
  );
}

export default AccountModal;
