import { CSSProperties } from 'react';
import { IconType } from '.';

interface DotsProps extends IconType {
  style?: CSSProperties;
}

export default function Dots({ className = 'icon', color = '#2A2D44', height = 6, style, width = 24 }: DotsProps) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 24 6">
      <circle cx="3" cy="3" r="3" fill={color} />
      <circle cx="3" cy="3" r="3" transform="translate(9)" fill={color} />
      <circle cx="3" cy="3" r="3" transform="translate(18)" fill={color} />
    </svg>
  );
}
