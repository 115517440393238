import cn from 'classnames';
import styles from './styles/FlexContainer.module.scss';

interface FlexContainerProps extends React.HTMLProps<HTMLDivElement> {
  column?: boolean;
}

export default function FlexContainer({ children, column, className, ...props }: FlexContainerProps) {
  return (
    <div className={cn(styles.FlexContainer, className, { [styles.Column]: column })} {...props}>
      {children}
    </div>
  );
}
