import { CSSProperties } from 'react';
import { IconType } from '.';

interface AccountProps extends IconType {
  style?: CSSProperties;
}

export default function Account({ className = 'icon', color = '#FFF', height = 40, style, width = 40 }: AccountProps) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 40 40">
      <g id="Layer_3" data-name="Layer 3" transform="translate(-2 -0.991)">
        <path
          fill={color}
          data-name="Path 1147"
          d="M29.4,20.485A10.321,10.321,0,0,0,33.353,8.343,11.976,11.976,0,0,0,22.008.991,11.976,11.976,0,0,0,10.663,8.343a10.321,10.321,0,0,0,3.953,12.142C7.01,23.236,2.014,29.917,2,37.356v3.635H42V37.356c-.012-7.435-5-14.115-12.6-16.871ZM14,11.912c0-4.015,3.582-7.27,8-7.27s8,3.255,8,7.27-3.582,7.27-8,7.27-8-3.255-8-7.27ZM6,37.356c0-8.03,7.163-14.539,16-14.539s16,6.509,16,14.539Z"
        />
      </g>
    </svg>
  );
}
