import { CSSProperties } from 'react';
import { IconType } from '.';

interface ExternalLinkProps extends IconType {
  style?: CSSProperties;
}

export default function ExternalLink({ className = '', color = '#2A2D44', height = 11.172, style, width = 11.172 }: ExternalLinkProps) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 11.172 11.172">
      <path
        fill={color}
        d="M6.983,33.2a.6.6,0,0,1,0-1.2h3.591a.6.6,0,0,1,.6.6V36.19a.6.6,0,0,1-1.2,0V34.042L4.99,38.985a.542.542,0,0,1-.825,0,.576.576,0,0,1,0-.825L9.13,33.2ZM0,34.195a1.4,1.4,0,0,1,1.4-1.4H4.19a.6.6,0,0,1,0,1.2H1.4a.2.2,0,0,0-.2.2v7.581a.2.2,0,0,0,.2.2H8.978a.2.2,0,0,0,.2-.2V38.983a.6.6,0,1,1,1.2,0v2.793a1.4,1.4,0,0,1-1.4,1.4H1.4a1.4,1.4,0,0,1-1.4-1.4Z"
        transform="translate(0 -32)"
      />
    </svg>
  );
}
