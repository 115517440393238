import { ChangeEvent, useState } from 'react';
import styles from './styles/Search.module.scss';
import cn from 'classnames';
import Link from './Link';
import TeamBlock from './TeamBlock';
import useThrottledEffect from 'hooks/useThrottledEffect';
import LinkIf from './LinkIf';
import Close from 'icons/Close';

interface SearchResultProps {
  bottomText: string;
  image?: string;
  link?: string;
  name: string;
  onClick?: () => void;
}

interface SearchProps<T> {
  className?: string;
  onSearch: (query: string) => Promise<T[]>;
  placeholder?: string;
  displayParser: (r: T) => SearchResultProps;
  viewAllLink?: (value: string) => string;
}

function Search<T>({ className, onSearch, placeholder, displayParser, viewAllLink }: SearchProps<T>) {
  const [value, setValue] = useState('');
  const [results, setResults] = useState<T[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useThrottledEffect(() => {
    if (value.length == 0) {
      setResults([]);
    } else {
      onSearch(value)
        .then(setResults)
        .finally(() => setIsLoading(false));
    }
  }, [value]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setIsLoading(e.target.value.length > 0);
    setShowResults(e.target.value.length > 0);
    setValue(e.target.value);
  }

  function handleBlur() {
    setShowResults(false);
  }

  function handleFocus() {
    setShowResults(value.length > 0);
  }

  function handleClearInput() {
    setIsLoading(false);
    setShowResults(false);
    setValue('');
  }

  return (
    <div className={cn(styles.Search, className)}>
      <input
        type="search"
        name="name"
        placeholder={placeholder}
        autoComplete="off"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {showResults && (
        <div className={styles.SearchResults} onMouseDown={e => e.preventDefault()}>
          <div className={styles.ResultHeader}>Results</div>
          <div className={styles.ResultContainer}>
            {results.map((result, i) => {
              const obj = displayParser(result);
              return (
                <LinkIf
                  key={i}
                  className={styles.SearchResult}
                  href={obj.link}
                  onClick={() => {
                    if (obj.onClick) obj.onClick();
                    setShowResults(false);
                  }}
                >
                  <TeamBlock
                    image={obj.image}
                    name={obj.name}
                    className={cn(styles.SearchResultTeamBlock, 'SearchResultTeamBlock')}
                    textBlock={
                      <div className={cn(styles.SearchResultText, 'SearchResultText')}>
                        <div>{obj.name}</div>
                        <div>{obj.bottomText}</div>
                      </div>
                    }
                  />
                </LinkIf>
              );
            })}
            {!isLoading && results.length === 0 && (
              <div className={styles.SearchResultText} style={{ marginLeft: '5px', marginTop: '10px' }}>
                <div>No Results</div>
              </div>
            )}
            {viewAllLink && results.length !== 0 && (
              <Link className={cn(styles.SearchResult, styles.ViewAllResults, 'ViewAllResults')} href={viewAllLink(value)}>
                View All
              </Link>
            )}
          </div>
        </div>
      )}
      {value && (
        <div onClick={handleClearInput} className={styles.ClearText}>
          <Close height={12} width={12} color="#000" />
        </div>
      )}
    </div>
  );
}

export default Search;
