import styles from 'components/styles/Input.module.scss';
import { useState } from 'react';
import Modal from './Modal';
import Label from './Label';
import Input from './Input';
import FlexContainer from './FlexContainer';
import { updateUserPassword } from 'stores/userStore';
import LoadingModal from './LoadingModal';
import Errors from './Errors';

interface PasswordModalProps {
  onClose: () => void;
  isOpen: boolean;
}

function PasswordModal({ isOpen, onClose }: PasswordModalProps) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSaving, setisSaving] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  function onSubmit() {
    setisSaving(true);
    setErrors(null);
    updateUserPassword(password, newPassword, confirmPassword)
      .then(response => {
        setisSaving(false);
        onClose();
      })
      .catch(errors => {
        setisSaving(false);
        setErrors(errors);
      });
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Change Password" onSubmit={onSubmit} submitText="save">
      <LoadingModal isOpen={isSaving} />
      <form onSubmit={onSubmit}>
        <Errors errors={errors} />
        <FlexContainer column className={styles.ModalSelect}>
          <Label text="Current Password" />
          <Input type="password" value={password} changeFunc={setPassword} modalSelect />
        </FlexContainer>
        <FlexContainer column className={styles.ModalSelect}>
          <Label text="New Password" />
          <Input type="password" value={newPassword} changeFunc={setNewPassword} modalSelect />
        </FlexContainer>
        <FlexContainer column className={styles.ModalSelect}>
          <Label text="Confirm New Password" />
          <Input type="password" value={confirmPassword} changeFunc={setConfirmPassword} modalSelect />
        </FlexContainer>
      </form>
    </Modal>
  );
}

export default PasswordModal;
