import styles from 'components/styles/Input.module.scss';
import { useState } from 'react';
import Modal from './Modal';
import Label from './Label';
import Input from './Input';
import FlexContainer from './FlexContainer';
import { requestUserAccountDeletion } from 'stores/userStore';
import LoadingModal from './LoadingModal';
import Errors from './Errors';
import { useApplicationContainer } from 'containers/ApplicationContainer';

interface PasswordModalProps {
  username: string | undefined;
  onClose: () => void;
  isOpen: boolean;
}

function AccountDeleteModal({ isOpen, onClose, username }: PasswordModalProps) {
  const [, { handleOpenLink }] = useApplicationContainer();
  const [accountName, setTypeAccountName] = useState('');
  const [isSaving, setisSaving] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  function onSubmit() {
    if (username !== accountName) {
      alert(`Invalid username. Please type username "${username}" to request account deletion.`);
    } else {
      setisSaving(true);
      setErrors(null);
      requestUserAccountDeletion()
        .then(() => {
          setisSaving(false);
          alert('You account deletion request has been submitted. You will now be logged out.');
          handleOpenLink('/'); //cannot logout deleted user, redirect home.
        })
        .catch(errors => {
          setisSaving(false);
          setErrors(errors);
        });
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Delete Account" onSubmit={onSubmit} submitText="Delete">
      <LoadingModal isOpen={isSaving} />
      <form onSubmit={onSubmit}>
        <Errors errors={errors} />
        <FlexContainer column className={styles.ModalSelect}>
          <Label text={`Type "${username}" and press delete`} />
          <div className="text-left">
            <p>WARNING: The following will delete all user information associated with this account and cannot be undone. </p>
          </div>
          <Input type="text" isChecked={false} value={accountName} changeFunc={setTypeAccountName} modalSelect />
        </FlexContainer>
      </form>
    </Modal>
  );
}

export default AccountDeleteModal;
