import styles from 'react/admin/components/admin_components.module.scss';
import cn from 'classnames';
import { ChangeEvent, useEffect, useRef } from 'react';

export interface SelectorProps<ValueType> {
  label?: string;
  value?: ValueType;
  onChange: (value?: ValueType) => void;
  options: (ValueType | [group: string, options: ValueType[]])[];
  className?: string;
  selectClassName?: string;
  placeholder?: string;
  marginRight?: boolean;
  disabled?: boolean;
  labelExtractor: (value: ValueType) => string;
  valueExtractor: (value: ValueType) => string;
  v2?: boolean;
  dynamicWidth?: boolean;
  limitWidth?: boolean;
}

export default function Selector<ValueType>({
  label,
  value,
  onChange,
  options,
  className,
  selectClassName,
  marginRight,
  placeholder,
  disabled,
  labelExtractor,
  valueExtractor,
  limitWidth,
  dynamicWidth,
  v2
}: SelectorProps<ValueType>) {
  const ref = useRef<HTMLSpanElement>(null);

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value) {
      const value = options
        .map(option => {
          if (Array.isArray(option)) {
            return option[1];
          } else {
            return [option];
          }
        })
        .flat()
        .find(option => valueExtractor(option) === e.target.value);

      onChange(value);
    } else {
      onChange(undefined);
    }
  }

  const selectStyle = {} as React.CSSProperties;
  if (dynamicWidth && value) {
    selectStyle['width'] = `${labelExtractor(value).length * 8 + 55}px`;
    if (ref.current) {
      ref.current.innerText = labelExtractor(value);
      selectStyle['width'] = `${ref.current.offsetWidth + 55}px`;
    }
  }

  return (
    <div className={cn(className, { [styles.MarginRight]: marginRight, [styles.LimitWidth]: limitWidth })}>
      {label && <div className={styles.SBLLabel}>{label}</div>}
      <span ref={ref} style={{ fontSize: '13px', fontWeight: '600', position: 'absolute', opacity: '0' }}></span>
      <select
        className={cn('select', styles.SBLSelector, selectClassName, { [styles.SelectorV2]: v2 })}
        onChange={handleChange}
        value={value ? valueExtractor(value) : undefined}
        disabled={disabled}
        style={selectStyle}
      >
        {placeholder && <option>{placeholder}</option>}
        {options.map((option, index) => {
          if (Array.isArray(option)) {
            const [group, groupOptions] = option;

            return (
              <optgroup label={group} key={group}>
                {groupOptions.map(groupOption => (
                  <option key={valueExtractor(groupOption)} value={valueExtractor(groupOption)}>
                    {labelExtractor(groupOption)}
                  </option>
                ))}
              </optgroup>
            );
          } else {
            return (
              <option key={index} value={valueExtractor(option)}>
                {labelExtractor(option)}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
}
